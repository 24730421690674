<template>
  <tr class='hover:bg-blue-100'>
    <td class=''>
      <div class='inline-block'>
        <a class='hover:underline' :href='announcementLink' target='_blank'>
          {{announcement.report_name}}
        </a>
      </div>
    </td>
    <td class='text-left'>{{announcement.share_code}}</td>
    <td class='text-left'>{{announcement.name}}</td>
    <td class='text-left'>{{market(announcement.market)}}</td>
    <td class='text-left'>{{announcement.action}}</td>
    <td class='text-left'>{{announcement.details['증자방식']}}</td>

    <td class='text-right border-l'>{{ numberStyle(totalQuantity) }}</td>
    <td class='text-right'>{{ numberStyle(totalAmount) }}</td>

    <td class='text-left'>{{dateStyle(announcement.details['납입일'])}}</td>
    <td class='text-left'>{{dateStyle(announcement.details['신주배정기준일'])}}</td>
    <td class='text-left'>{{dateStyle(announcement.details['상장예정일'])}}</td>
    <td class='text-right'>{{announcement.details['할인율또는할증율']}}</td>

    <td class='text-right border-l'>{{dateStyle(announcement.details['배당기산일'])}}</td>
    <td class='text-left'>{{dateStyle(announcement.details['우리사주조합시작일'])}}</td>
    <td class='text-left'>{{dateStyle(announcement.details['우리사주조합종료일'])}}</td>
    <td class='text-left'>{{dateStyle(announcement.details['구주주시작일'])}}</td>
    <td class='text-left'>{{dateStyle(announcement.details['구주주종료일'])}}</td>

    <td class='text-right border-l'>{{ numberStyle(announcement.details['보통주'] || 0) }}</td>
    <td class='text-right'>{{ numberStyle(priortyQuantity) }}</td>
    <td class='text-right border-l'>{{ numberStyle(announcement.details['시설자금'] || 0) }}</td>
    <td class='text-right'>{{ numberStyle(announcement.details['영업양수자금'] || 0) }}</td>
    <td class='text-right'>{{ numberStyle(announcement.details['운영자금'] || 0) }}</td>
    <td class='text-right'>{{ numberStyle(announcement.details['채무상환자금'] || 0) }}</td>
    <td class='text-right'>{{ numberStyle(announcement.details['타법인증권취득자금'] || 0) }}</td>
    <td class='text-right'>{{ numberStyle(announcement.details['기타자금'] || 0) }}</td>

    <td class='text-right border-l'>{{announcement.receipt_date}}</td>
    <td class=''>{{announcement.receipt_number}}</td>
  </tr>
</template>

<script>
import dayjs from 'dayjs'

export default {
  name: 'CorporateAnnouncementStockRow',
  props: ['announcement'],
  computed: {
    announcementLink () {
      return this.announcement.url ? this.announcement.url : ''
    },
    hasAnnouncementDetails () {
      return !this.announcement.details
    },
    priortyQuantity () {
      return this.hasAnnouncementDetails ?
              this.announcement.details['우선주'] === undefined ? this.announcement.details['기타주'] : this.announcement.details['우선주'] : 0
    },
    totalQuantity () {
      return Number(this.announcement.details['보통주']) + Number(this.priortyQuantity) || 0
    },
    totalAmount () {
      return Number(this.announcement.details['시설자금']) + Number(this.announcement.details['영업양수자금']) + Number(this.announcement.details['운영자금']) +
            Number(this.announcement.details['채무상환자금']) + Number(this.announcement.details['타법인증권취득자금']) + Number(this.announcement.details['기타자금']) || 0
    }
  },
  methods: {
    market (rawMarket) {
      let str = ''
      switch (rawMarket) {
        case 'kospi':
          str = '유'
          break
        case 'kosdaq':
          str = '코'
          break
        case 'konex':
          str = '넥'
          break
        case 'etc':
          str = '기'
          break
      }
      return str
    },
    numberStyle (number) {
      return Number(number).toLocaleString()
    },
    dateStyle (string) {
      if (string) {
        return (string.length === 8) ? dayjs(string).format('YYYY-MM-DD') : string
      } else {
        return ''
      }
    },
  },
}
</script>

<style lang='scss' scoped>
  td {
    @apply py-3 pl-2 pr-6 border-b;
    white-space: nowrap;
  }
  td:first-child {
    position: sticky;
    background-color: #FFF;
    left: 0;
  }
</style>
